<template>
  <v-card color="transparent">
    <v-card-title class="primary text-body-1 font-weight-bold">
      {{ title }}
      <!-- <v-spacer></v-spacer>
      <div class="text-body-1">
        5ステップ簡単予約
      </div> -->
    </v-card-title>

    <v-stepper
      class="rounded-0"
      v-model="currentStep"
      vertical
      light
    >
      <!-- *************** ステップ１ 会員番号を入力 *************** -->
      <v-stepper-step step="1"
        :complete="currentStep > 1"
        color="primary"
      >
        <div class="d-flex align-center">
          会員番号を入力 <v-icon class="mx-2">mdi-arrow-right-thin-circle-outline</v-icon> {{ customerId }}番
        </div>
      </v-stepper-step>
      <v-stepper-content step="1"
        class="pt-1 pb-4"
      >
        <small class="grey--text">LINE友だち登録時に通知された会員番号になります。（＋ーで微調整可能です）</small>
        <!-- 入力 -->
        <v-slider
          class="mt-10"
          disabled
          v-model="customerId"
          :min="customerNumMin"
          :max="customerNumMax"
          thumb-color="primary"
          thumb-label="always"
          prepend-icon="mdi-minus-circle-outline"
          append-icon="mdi-plus-circle-outline"
          @click:prepend="customerId--"
          @click:append="customerId++"
        >
        </v-slider>

        <!-- ボタン -->
        <div class="d-flex mb-2">
          <v-btn class="mt-2 mr-2 px-2"
            :disabled="this.customerId == 0"
            depressed
            height="30"
            color="accent"
            @click="currentStep++"
          >
            次へ
          </v-btn>
          <v-btn class="mt-2 px-2"
            text
            height="30"
            @click="close"
          >
            閉じる
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mt-2 px-2"
            outlined
            color="accent"
            height="30"
            @click="customerNumMin = customerNumMax; customerNumMax = customerNumMax + 500"
          >
            {{ customerNumMax }}以上
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- *************** ステップ２ 本指名 *************** -->
      <v-stepper-step step="2"
        :complete="currentStep > 2"
        color="primary"
      >
        <div class="d-flex align-center">
          本指名？ or 初めて？ <v-icon class="mx-2">mdi-arrow-right-thin-circle-outline</v-icon> {{ confirmedHonshi }}
        </div>
      </v-stepper-step>
      <v-stepper-content step="2"
        class="pt-1 pb-4"
      >
        <!-- 入力 -->
        <p class="ma-0 grey--text">{{ this.shiftInfo.cast_name }}さんのご予約は本指名ですか？</p>
        <v-switch
          class="ml-3 mt-2"
          v-model="isHonshi"
          inset
          :label="`${isHonshi ? 'はい、本指名です' : 'いえ、初めてです'}`"
        ></v-switch>

        <!-- ボタン -->
        <div class="d-flex mb-2">
          <v-btn class="mt-2 mr-2 px-2"
            depressed
            height="30"
            color="accent"
            @click="toStepPickSlot(3)"
          >
            次へ
          </v-btn>
          <v-btn class="mt-2 px-2"
            text
            height="30"
            @click="close"
          >
            やめる
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- *************** ステップ３ 予約枠選択 *************** -->
      <v-stepper-step step="3"
        :complete="currentStep > 3"
        color="primary"
      >
        <div class="d-flex align-center">
          予約枠を選ぶ <v-icon class="mx-2">mdi-arrow-right-thin-circle-outline</v-icon> {{ confirmedHour }}
        </div>
      </v-stepper-step>
      <v-stepper-content step="3"
        class="pt-1 pb-4"
      >
        <!-- 入力 -->
        <v-chip-group
          class="mb-3"
          v-model="pickedSlot"
          column
          active-class="primary--text primary lighten-4"
        >
          <v-chip
            v-for="hour in availableHours"
            :key="hour"
          >
            {{ hour }}時
          </v-chip>
        </v-chip-group>

        <p class="grey--text"
          v-if="availableHours.length == 0"
        >
          申し訳ありませんが空いている枠が無い様です。
        </p>

        <!-- ボタン -->
        <div class="d-flex mb-2">
          <v-btn class="mt-2 mr-2 px-2"
            :disabled="pickedSlot === null || pickedSlot === undefined"
            :loading="btnLoading"
            depressed
            height="30"
            color="accent"
            @click="toStepPickCourse(4)"
          >
            次へ
          </v-btn>
          <v-btn class="mt-2 px-2"
            :disabled="btnLoading"
            text
            height="30"
            @click="close"
          >
            やめる
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- *************** ステップ４ コース選択 *************** -->
      <v-stepper-step step="4"
        :complete="currentStep > 4"
        color="primary"
      >
        <div class="d-flex align-center">
          コースを選ぶ <v-icon class="mx-2">mdi-arrow-right-thin-circle-outline</v-icon> {{ confirmedCourse }}
        </div>
      </v-stepper-step>
      <v-stepper-content step="4"
        class="pt-1 pb-4"
      >
        <!-- コースタイプ入力 -->
        <v-chip-group
          v-model="pickedCourseType"
          column
          active-class="primary--text primary lighten-4"
          v-show="courseTypes.length > 1"
        >
          <v-chip
            v-for="type in courseTypes"
            :key="type.course_type_id"
          >
            {{ type.course_type }}
          </v-chip>
        </v-chip-group>

        <!-- コース入力 -->
        <v-chip-group
          v-model="pickedCourse"
          column
          active-class="primary--text primary lighten-4"
          v-show="pickedCourseType >= 0"
        >
          <v-chip
            v-for="course in availableCourses"
            :key="course.course_id"
          >
            {{ course.course_name }}
          </v-chip>
        </v-chip-group>

        <!-- ボタン -->
        <div class="d-flex mb-2">
          <v-btn class="mt-2 mr-2 px-2"
            :disabled="pickedCourse === null || pickedCourse === undefined"
            :loading="btnLoading"
            depressed
            height="30"
            color="accent"
            @click="currentStep++"
          >
            次へ
          </v-btn>
          <v-btn class="mt-2 px-2"
            :disabled="btnLoading"
            text
            height="30"
            @click="close"
          >
            やめる
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- *************** ステップ5 備考入力 *************** -->
      <v-stepper-step step="5"
        :complete="currentStep > 5"
        color="primary"
      >
        備考を入力
      </v-stepper-step>
      <v-stepper-content step="5"
        class="py-2"
      >
        <v-textarea
          v-model="note"
          filled
          auto-grow
          label="ご要望等ありましたらご記入下さい。"
          rows="4"
          row-height="20"
          shaped
          color="deep-purple"
        ></v-textarea>

        <!-- ボタン -->
        <div class="d-flex mb-2">
          <v-btn class="mt-2 mr-2 px-2"
            :loading="btnLoading"
            depressed
            height="30"
            color="accent"
            @click="requestBooking(6)"
          >
            申し込む
          </v-btn>
          <v-btn class="mt-2 px-2"
            :disabled="btnLoading"
            text
            height="30"
            @click="close"
          >
            やめる
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- *************** ステップ完了 *************** -->
      <v-stepper-step step="6"
        color="primary"
      >
        完了
      </v-stepper-step>
      <v-stepper-content step="6"
        class="py-2"
      >
        <div class="my-3"
          v-html="messageComplete"
        >
        </div>

        <!-- ボタン -->
        <v-btn class="mt-2 px-2"
          outlined
          height="30"
          color="primary"
          @click="close"
        >
          閉じる
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-card>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import 'moment/locale/ja'
import { API_ENDPOINT } from '@/literals.js'
import { BizHour, CiApiTool } from '@/module.js';

export default {
  components: {
  },

  props: {
    shopId: {
      type: String,
      required: true,
    },
    aToken: {
      type: String,
      required: true,
    },
    bizHours: {
      type: Object,
      required: true,
      default: () => ({})
    },
    shiftInfo: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      currentStep: 2,
      customerNumMin: 0,
      customerNumMax: 500,
      customerId: 0,
      isHonshi: false,
      bookings: [],
      courses: [],
      availableHours: [],
      pickedSlot: null,
      courseTypes: [],
      pickedCourseType: null,
      availableCourses: [],
      pickedCourse: null,
      note: '',
      messageComplete: '',
      btnLoading: false,
      bizHour: new BizHour(this.bizHours),
      apiTool: new CiApiTool(API_ENDPOINT, this.shopId, this.aToken),
    };
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    jpDate() {
      return date => {
        const bizToday = moment(this.bizHour.getBizOpening(new Date())).format('YYYYMMDD')
        if (moment(date).format('YYYYMMDD') === bizToday) {
          return '本日'
        } else {
          return moment(date).format('M/D(dd)')
        }
      }
    },
    title() {
      return this.shiftInfo.cast_name + 'さん ' + this.jpDate(this.shiftInfo.shift_date) + 'のご予約'
    },
    hourStr() {
      return hourNum => ('0'+ hourNum).slice(-2)
    },
    confirmedHonshi() {
      if (this.currentStep < 3) {
        return ''
      } else {
        return this.isHonshi ? '本指名' : '初めて'
      }
    },
    confirmedHour() {
      if (this.pickedSlot != null && this.pickedSlot >= 0) {
        return this.jpDate(this.shiftInfo.shift_date) + ' ' + this.availableHours[this.pickedSlot] + '時'
      }
      return ''
    },
    confirmedCourse() {
      if (this.pickedCourse != null && this.pickedCourse >= 0) {
        return this.courseTypes[this.pickedCourseType].course_type + ' ' +
              this.availableCourses[this.pickedCourse].course_name
      }
      return ''
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    this.apiTool.getReqCi('customer-id/').then( data => this.customerId = data )
    .catch(() => alert('エラーが発生しました\nしばらくしてからもう一度試して下さい') )
  },

  //***************************************************
  // Watch
  //***************************************************
  watch: {
    pickedCourseType: async function(now) {
      this.pickedCourse = null
      if (now === undefined || now === null) return

      this.btnLoading = true

      if (this.courses.length) {
        this.setAvailableCourses()
      } else {
        await this.getCourses()
        this.setAvailableCourses()
      }

      this.btnLoading = false
    }
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    //***************************************************
    //予約枠の選択に進む前に空き時刻配列を取得
    //
    async toStepPickSlot(nextStep) {
      this.btnLoading = true

      await this.setAvailableHours()

      this.btnLoading = false
      this.currentStep = nextStep
    },

    //***************************************************
    // API req: 空き時刻配列を生成
    //
    setAvailableHours() {
      const apiPartial = 'cast/' + this.shiftInfo.cast_id + '/booking/'
      const query = {fromDate: this.shiftInfo.start_at, toDate: this.shiftInfo.end_at}

      return this.apiTool.getReqPublic(apiPartial, query).then( bookings => {
        const now = new Date()
        const shiftDate = moment(this.shiftInfo.shift_date).format('YYYY-MM-DD')
        this.availableHours = this.bizHour.getBizHourArray()

        //基本的な不要スロットを配列から除外
        this.availableHours = this.availableHours.filter( hour => {
          const hourAdjusted = this.bizHour.getAdjustedDatetime(shiftDate, this.hourStr(hour) + ':00')
          let isValid = true

          //出勤時間外 || 本日予約で現在時刻より以前
          if (moment(hourAdjusted).isBefore(this.shiftInfo.start_at) ||
              moment(hourAdjusted).isAfter(this.shiftInfo.end_at) ||
             (moment(now).isAfter(this.shiftInfo.start_at) && moment(hourAdjusted).isBefore(now))
          ) {
            isValid = false
          }
          return isValid
        })

        if (!bookings) return
        this.bookings = bookings

        //既存予約に絡んだスロットを除外
        this.availableHours = this.availableHours.filter( hour => {
          let isAvailable = true
          const slotStart = this.bizHour.getAdjustedDatetime(shiftDate, this.hourStr(hour) + ':00')
          const slotEnd = this.bizHour.getAdjustedDatetime(shiftDate, this.hourStr(hour + 1) + ':00')

          bookings.some( booking => {
            //予約に触れてる || すっぽり中に入ってるスロットをfilter除外
            if (
              (moment(booking.start_at).isSameOrAfter(slotStart) && moment(booking.start_at).isBefore(slotEnd)) ||
              (moment(booking.end_at).isAfter(slotStart) && moment(booking.end_at).isSameOrBefore(slotEnd)) ||
              (moment(booking.start_at).isBefore(slotStart) && moment(booking.end_at).isAfter(slotEnd))
            ) {
              isAvailable = false
              return true
            }
          })
          return isAvailable
        })
      })
      .catch(error => alert('エラーが発生しました\nしばらくしてからもう一度試して下さい\n'+ error) )
    },

    //***************************************************
    //コース選択に進む前に可能コース配列を取得
    //
    async toStepPickCourse(nextStep) {
      this.btnLoading = true

      if (!this.courseTypes.length) {
        await this.setCourseTypes()
      }

      //タイプがひとつならコースを取得
      if (this.courseTypes.length == 1) {
        this.pickedCourseType = 0
        await this.getCourses()
        this.setAvailableCourses()
      }

      this.btnLoading = false
      this.currentStep = nextStep
    },

    //***************************************************
    // API req: コースタイプ配列を生成
    //
    setCourseTypes() {
      return this.apiTool.getReqPublic('course-type/').then( types => {
        if (!types) return
        this.courseTypes = types
      })
      .catch(error => alert('エラーが発生しました\nしばらくしてからもう一度試して下さい\n'+ error) )
    },

    //***************************************************
    // API req: コースを取得
    //
    getCourses() {
      return this.apiTool.getReqPublic('course/').then( courses => {
        if (!courses) return
        this.courses = courses
      })
      .catch(error => alert('エラーが発生しました\nしばらくしてからもう一度試して下さい\n'+ error) )
    },

    //***************************************************
    //選択したコースタイプのコース配列を生成
    //
    setAvailableCourses() {
      const shiftDate = moment(this.shiftInfo.shift_date).format('YYYY-MM-DD')
      const pickedHour = this.availableHours[this.pickedSlot]
      const pickedDt = this.bizHour.getAdjustedDatetime(shiftDate, pickedHour + ':00')
      let minsTilNextBooking = 0

      if (this.bookings.length === 0) {
        minsTilNextBooking = moment(this.shiftInfo.end_at).diff(this.shiftInfo.start_at, 'minutes')
      } else {
        //次の予約までの空き分数を取得
        this.bookings.some( booking => {
          const diff = moment(booking.start_at).diff(pickedDt, 'minutes')
          if (diff > 0) {
            minsTilNextBooking = diff
            return true
          }
        })
        //後ろに予約がないなら
        if (minsTilNextBooking === 0) {
          minsTilNextBooking = moment(this.shiftInfo.end_at).diff(this.shiftInfo.start_at, 'minutes')
        }
      }

      //タイプと空き分数でfilter
      this.availableCourses = this.courses.filter( course => {
        return  this.courseTypes[this.pickedCourseType].course_type_id === course.course_type_id &&
                course.course_mins <= minsTilNextBooking
      })
    },

    //***************************************************
    //予約申込
    //
    requestBooking(nextStep) {
      this.btnLoading = true

      const shiftDate = moment(this.shiftInfo.shift_date).format('YYYY-MM-DD')
      const pickedHour = this.hourStr(this.availableHours[this.pickedSlot])
      const startAt = this.bizHour.getAdjustedDatetime(shiftDate, pickedHour + ':00')
      const endAt = moment(startAt).add(this.availableCourses[this.pickedCourse].course_mins, 'm').format('YYYY-MM-DD HH:mm')

      const formData = new FormData()
      formData.append('customer_id', this.customerId)
      formData.append('cast_id', this.shiftInfo.cast_id)
      formData.append('booking_type', 'ネット')
      formData.append('booking_status', '仮予約')
      formData.append('start_at', startAt)
      formData.append('end_at', endAt)
      formData.append('course_id', this.availableCourses[this.pickedCourse].course_id)
      formData.append('is_honshi', this.isHonshi)
      formData.append('place', '')
      formData.append('note', this.note)
      formData.append('booker_name', '')
      formData.append('booker_phone', '')

      this.apiTool.apiReqCiWithData('POST', 'create/booking/', formData).then( response => {
        if (response.notExistsCustomer) {
          this.messageComplete = "<p class='red--text'>お客様のLINEアカウントに紐づく会員情報が存在しないためお申し込みが完了出来ませんでした。<br />"+
              "お手数ですが店舗アカウントを友だち再追加してから再度お試し下さい。</p>"
        } else {
          this.messageComplete = "<p class='black--text'>予約お申し込みありがとうございました。<br />"+
              "ご予約内容確認のため店舗よりLINEメッセージが届きますので少々お待ち下さい。</p>"+
              "<small class='d-block my-1 grey--text'>しばらく経っても連絡が無い場合はお手数ですが店舗まで直接ご連絡下さい。</small>"
        }
      })
      .catch(() => {
        this.messageComplete = "<p class='orange--text'>申し訳ありませんがエラーが発生したためお申し込みは完了できませんでした。<br />" +
            "しばらくしてから再度お申し込みいただくか、直接店舗へご連絡下さい。</p>"
      })
      .then(() => {
        this.btnLoading = false
        this.currentStep = nextStep
      });
    },

    //***************************************************
    //フォームクローズ：リセット
    //
    close() {
      this.$emit('cancel')
      this.btnLoading = false
      this.currentStep = 2
      this.isHonshi = false
      this.customerNumMin = 0
      this.customerNumMax = 500
      this.pickedSlot = null
      this.pickedCourse = null
      this.pickedCourseType = null
      this.note = ''
      this.bookings.length = 0
      this.availableHours.length = 0
      this.availableCourses.length = 0
    },
  }
};
</script>

<style scoped>
</style>
