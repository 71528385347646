<template>
  <v-card class="today-casts px-1 py-2"
    flat
    width="100vw"
    color="transparent"
  >
    <!-- 出勤０ -->
    <v-row
      v-if="castsIn.length == 0"
    >
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            本日出勤予定のキャストがいません。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- 出勤あり -->
    <v-row
      no-gutters
      v-else
    >
      <v-col cols="6" sm="3" md="2" xl="2"
        class="cast d-flex mb-2"
        v-for="cast in castsIn"
        :key="cast.cast_id"
      >
        <cast-card
          :castData="cast"
          @bookingClicked="bookingClicked(cast)"
        ></cast-card>
      </v-col>
    </v-row>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

    <!-- 予約フォーム -->
    <v-bottom-sheet
      v-model="openBooking"
      inset persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="100vw"
      overlay-opacity="0.5"
    >
      <form-booking
        :shopId="shopId"
        :aToken="aToken"
        :bizHours="bizHours"
        :shiftInfo="shiftInfo"
        @cancel="openBooking = false"
      ></form-booking>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import moment from 'moment'
import { API_ENDPOINT } from '@/literals.js'
import { BizHour, CiApiTool } from '@/module.js';
import Loader from '@/components/_Loader.vue'
import CastCardMini from '@/components/_CastCardMini.vue'
import FormCastBooking from '@/components/_FormCastBooking.vue'

export default {
  components: {
    'loader': Loader,
    'cast-card': CastCardMini,
    'form-booking': FormCastBooking,
  },

  props: {
    shopId: {
      type: String,
      required: true,
    },
    aToken: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      casts: [],
      castsIn: [],
      shiftInfo: {cast_name: ''},
      openBooking: false,
      bizHours: {},
      loading: false,
      loadingMessage: null,
      bizHour: null,
      apiTool: new CiApiTool(API_ENDPOINT, this.shopId, this.aToken),
    };
  },

  computed: {
    dateToday() {
      return moment(this.bizHour.getBizOpening(new Date())).format('YYYYMMDD')
    },
  },

  created() {
    this.init()
    .catch(() => alert('データ取得中にエラーが発生しました\nしばらくしてからもう一度試して下さい') )
  },

  methods: {
    async init() {
      this.loading = true
      this.loadingMessage = '出勤データ取得中・・・'

      await this.apiTool.getReqPublic('shop-biz-hours/').then( data => {
        if (!data) return
        this.bizHours = data
        this.bizHour = new BizHour(data)
      })

      await this.apiTool.getReqPublic('cast/').then( results => {
        if (!results || !results.length) return
        this.casts = results
      })

      await this.apiTool.getReqPublic('shift/date/', {date: this.dateToday}).then( results => {
        if (!results || !results.length) return

        //取得したシフトをキャストデータに紐付け
        results.forEach( shift => {
          this.casts.forEach( cast => {
            if (cast.cast_id === shift.cast_id) {
              this.castsIn.push({...cast, ...shift})
            }
          })
        })
      })

      this.loading = false
    },

    //予約ボタンクリック：フォームオープン
    bookingClicked(cast) {
      this.shiftInfo.cast_id = cast.cast_id
      this.shiftInfo.cast_name = cast.name
      this.shiftInfo.shift_date = cast.shift_date
      this.shiftInfo.start_at = cast.start_at
      this.shiftInfo.end_at = cast.end_at
      this.openBooking = true
    },
  }
};
</script>

<style scoped>
</style>
